@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap");
.bg_primary {
  background: #1a1a1a !important;
}

.bg_secondary {
  background: #ed1c24 !important;
}

.bg_yellow {
  background: #ffd700;
}

.bg_blue {
  background: #270486;
}

.bg_gray {
  background: #555555;
}

.bg_ash {
  background: #f3f3f3;
}

.bg_ash2 {
  background: #f9f9f9;
}

.bg_black {
  background: black !important;
}

.bg_orange {
  background: #ec5717;
}

.bg_white_edgar {
  background: #ededed;
}

.bg_white_transparent_9 {
  background: rgba(255, 255, 255, 0.9);
}

.bg_black_transparent_7 {
  background: rgba(0, 0, 0, 0.7);
}

.fw_bold {
  font-weight: 700;
}

.fw_semi_bold {
  font-weight: 600;
}

.text_secondary {
  color: #ed1c24;
}

.text_orange {
  color: #ec5717;
}

.bg_none {
  background: none;
}

.border_none {
  border: none !important;
}

.border_radius_none {
  border-radius: 0 !important;
}

.bg_cover {
  background-size: cover !important;
}

.bg_norepeat {
  background-repeat: no-repeat !important;
}

.bg_center {
  background-position: center !important;
}

.border_shadow1 {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
}

.vm_fs_21 {
  font-size: 21px;
}

.vm_fs_18 {
  font-size: 18px !important;
}

.vm_fs_15 {
  font-size: 15px !important;
}

.vm_fs_13 {
  font-size: 13px !important;
}

.vm_lh_18 {
  line-height: 18px !important;
}

body {
  color: #000000;
  font-family: "Barlow";
}

p {
  line-height: 28px !important;
  color: #000000;
  font-size: 18px !important;
  font-family: "Barlow";
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-family: "Barlow";
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 55px;
  line-height: 45px;
}

h3 {
  font-size: 40px;
  line-height: 36px;
}

h4 {
  font-size: 32px;
  line-height: 36px;
}

h5 {
  font-size: 25px;
  line-height: 36px;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.container {
  max-width: 1500px !important;
}

::-moz-placeholder {
  color: white !important;
}

:-ms-input-placeholder {
  color: white !important;
}

::placeholder {
  color: white !important;
}

header .first_layer {
  z-index: 99;
  position: relative;
}
header .second_layer {
  background: rgba(237, 28, 36, 0.7);
}
@media (min-width: 481px) and (max-width: 1000px) {
  header {
    background: black;
  }
}
@media (max-width: 480px) {
  header {
    background: black;
  }
}
header .overlay_black {
  height: 504px;
}
header .logo_wrapper {
  filter: drop-shadow(11px 8px 9px rgba(50, 50, 0, 0.1));
  height: 137px;
  width: 30%;
  position: absolute;
  left: 0;
  z-index: 99;
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .logo_wrapper {
    position: relative;
    width: 100%;
    height: 97px;
  }
}
@media (max-width: 480px) {
  header .logo_wrapper {
    position: relative;
    width: 100%;
    height: 97px;
  }
}
header .logo_wrapper .logo {
  height: 137px;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
  padding-right: 80px;
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .logo_wrapper .logo {
    height: 99px;
    -webkit-clip-path: none;
            clip-path: none;
    padding-right: 0;
    padding-left: 20px;
  }
}
@media (max-width: 480px) {
  header .logo_wrapper .logo {
    height: 99px;
    -webkit-clip-path: none;
            clip-path: none;
    padding-right: 0;
    padding-left: 20px;
  }
}
header .menu_wrapper {
  width: 71%;
  z-index: 800;
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper {
    padding: 0;
    width: 100%;
    position: relative;
    background: #ed1c24 !important;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper {
    padding: 0;
    width: 100%;
    position: relative;
    background: #ed1c24 !important;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .top_menu {
    background: #555555;
    display: block !important;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .top_menu {
    background: #555555;
    display: block !important;
  }
}
header .menu_wrapper .sign_menu {
  z-index: 999999;
  position: relative;
}
@media (max-width: 1000px) {
  header .menu_wrapper .sign_menu {
    font-size: 20px;
  }
}
header .menu_wrapper .sign_menu .showing {
  background: #f3f3f3;
  color: black;
  border-radius: 4px;
}
@media (max-width: 1000px) {
  header .menu_wrapper .sign_menu .showing {
    background: #ed1c24;
    color: white;
    border-radius: 0;
    padding: 5px;
    text-align: center;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .sign_menu .item {
    width: 100%;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu .item {
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .sign_menu .item p {
    background: #ed1c24;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  header .menu_wrapper .sign_menu .item p:first-child {
    border-right: 1px solid white;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu .item p {
    background: #ed1c24;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu .item:first-child p {
    border-right: 1px solid white;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .sign_menu {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
header .hvr-sweep-to-right:before {
  background: #ed1c24;
}

.scroll {
  color: #fff;
  margin-top: 100px;
}

#toTopBtn {
  position: fixed;
  bottom: 26px;
  right: 39px;
  z-index: 98;
  padding: 21px;
  background-color: #ed1c24;
}

.js .cd-top--fade-out {
  opacity: 0.5;
}

.js .cd-top--is-visible {
  visibility: visible;
  opacity: 1;
}

.js .cd-top {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s, background-color 0.3s;
}

.cd-top {
  position: fixed;
  bottom: 20px;
  bottom: var(--cd-back-to-top-margin);
  right: 20px;
  right: var(--cd-back-to-top-margin);
  display: inline-block;
  height: 40px;
  height: var(--cd-back-to-top-size);
  width: 40px;
  width: var(--cd-back-to-top-size);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05) !important;
  background: url(https://res.cloudinary.com/dxfq3iotg/image/upload/v1571057658/cd-top-arrow.svg) no-repeat center 50%;
  background-color: hsla(5deg, 76%, 62%, 0.8);
  background-color: hsla(var(--cd-color-3-h), var(--cd-color-3-s), var(--cd-color-3-l), 0.8);
}

.owl-carousel .owl-nav {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  transition: all 0.5 ease;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  background: rgba(237, 28, 36, 0.8) !important;
  height: 50px;
  width: 50px;
  margin-top: 0 !important;
  border-radius: 100% !important;
  color: white;
}
.owl-carousel .owl-nav .owl-prev span,
.owl-carousel .owl-nav .owl-next span {
  position: absolute;
  margin-top: -32px;
  color: white;
}
.owl-carousel .owl-nav .owl-prev {
  left: 21px;
}
.owl-carousel .owl-nav .owl-prev span {
  margin-left: -9px;
}
.owl-carousel .owl-nav .owl-next {
  right: 0px;
}
.owl-carousel .owl-nav .owl-next span {
  margin-left: -8px !important;
}
.owl-carousel:hover .owl-nav {
  opacity: 9;
}

/* vars */
/* hide show mixin */
/* global  styles */
.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu,
.menu a {
  color: #e2e2e2;
  text-decoration: none;
}

.menu a {
  display: block;
  white-space: nowrap;
}
@media (max-width: 1000px) {
  .menu a {
    text-align: center;
    line-height: 40px;
  }
}

.menu-dropdown,
.menu input[type=checkbox] {
  display: none;
}

.menu label:hover {
  cursor: pointer;
}

.mob_menu {
  display: none;
}

/* narrow styles */
@media screen and (max-width: 1024px) {
  .mob_menu {
    display: block;
  }
  .menu > ul,
.menu-righticon {
    display: none;
  }
  input[type=checkbox]:checked + ul {
    display: block;
    -webkit-animation: grow 0.5s ease-in-out;
            animation: grow 0.5s ease-in-out;
  }
}
@media screen and (max-width: 1024px) and (max-width: 1000px) {
  input[type=checkbox]:checked + ul {
    position: fixed;
    background: url("../img/guna_homes_main_banner1.png");
    background-size: cover;
    width: 100%;
    top: 0;
    left: 0px;
    height: 100%;
    z-index: 999;
    padding: 10px 0px;
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  input[type=checkbox]:checked + ul .mob_menu {
    position: absolute;
    top: 20px;
    right: 0;
    background: black;
    padding: 5px 9px;
  }
}
@media screen and (max-width: 1024px) {
  input[type=checkbox]:checked + ul li {
    border-bottom: 1px solid #4a4a4a;
    width: 50%;
    padding-top: 3px;
    scroll-padding-bottom: 3px;
    background: rgba(0, 0, 0, 0.7);
  }
}
/* large styles */
@media screen and (min-width: 1025px) {
  .menu > label,
input[type=checkbox] {
    display: none;
    z-index: 9999999;
    position: relative;
  }
  .menu a {
    padding: 0 11px;
  }
  .menu > ul > li {
    display: inline-block;
  }
  .menu-hasdropdown {
    position: relative;
  }
  .menu-hasdropdown:hover > ul {
    display: block;
    -webkit-animation: grow 0.5s ease-in-out;
            animation: grow 0.5s ease-in-out;
  }
  .menu-hasdropdown > ul {
    position: absolute;
    top: 100%;
    left: 0;
    background: #000000;
    z-index: 99999;
    padding-top: 10px;
  }
  .menu-hasflyout > ul {
    left: 100%;
    top: 0;
  }
  .menu-hasflyout .menu-downicon {
    display: none;
  }
}
/* look and feel only, not needed for core menu*/
@-webkit-keyframes grow {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: block;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes grow {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: block;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.menu-dropdown a {
  padding: 5px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* narrow  */
@media screen and (max-width: 1024px) {
  .menu > label {
    background: black;
    display: block;
    padding: 0 11px;
    text-align: right;
  }
  .menu a {
    padding: 0 11px;
  }
  .menu > ul i {
    float: right;
    padding: 5px 10px;
    background: black;
  }
  .menu-dropdown a {
    background: black;
  }
  .menu-hasflyout > ul a {
    background: black;
  }
}
@media screen and (min-width: 1025px) {
  .menu {
    max-width: 1024px;
    margin: 0 auto;
  }
}