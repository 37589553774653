footer {
  background: url(../img/guna_homes_footer_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 80px;
}
footer .top {
  border-bottom: 1px solid #484848;
}
footer .top .line {
  border-left: 1px solid #484848;
  margin-left: 25px;
}

.footer-links div :hover {
  cursor: pointer;
}