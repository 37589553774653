.bg_primary {
  background: #1a1a1a !important;
}

.bg_secondary {
  background: #ed1c24 !important;
}

.bg_yellow {
  background: #ffd700;
}

.bg_blue {
  background: #270486;
}

.bg_gray {
  background: #555555;
}

.bg_ash {
  background: #f3f3f3;
}

.bg_ash2 {
  background: #f9f9f9;
}

.bg_black {
  background: black !important;
}

.bg_orange {
  background: #ec5717;
}

.bg_white_edgar {
  background: #ededed;
}

.bg_white_transparent_9 {
  background: rgba(255, 255, 255, 0.9);
}

.bg_black_transparent_7 {
  background: rgba(0, 0, 0, 0.7);
}

.fw_bold {
  font-weight: 700;
}

.fw_semi_bold {
  font-weight: 600;
}

.text_secondary {
  color: #ed1c24;
}

.text_orange {
  color: #ec5717;
}

.bg_none {
  background: none;
}

.border_none {
  border: none !important;
}

.border_radius_none {
  border-radius: 0 !important;
}

.bg_cover {
  background-size: cover !important;
}

.bg_norepeat {
  background-repeat: no-repeat !important;
}

.bg_center {
  background-position: center !important;
}

.border_shadow1 {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
}

.vm_fs_21 {
  font-size: 21px;
}

.vm_fs_18 {
  font-size: 18px !important;
}

.vm_fs_15 {
  font-size: 15px !important;
}

.vm_fs_13 {
  font-size: 13px !important;
}

.vm_lh_18 {
  line-height: 18px !important;
}

body {
  color: #000000;
  font-family: "Barlow";
}

p {
  line-height: 28px !important;
  color: #000000;
  font-size: 18px !important;
  font-family: "Barlow";
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-family: "Barlow";
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 55px;
  line-height: 45px;
}

h3 {
  font-size: 40px;
  line-height: 36px;
}

h4 {
  font-size: 32px;
  line-height: 36px;
}

h5 {
  font-size: 25px;
  line-height: 36px;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.container {
  max-width: 1500px !important;
}

::-moz-placeholder {
  color: white !important;
}

:-ms-input-placeholder {
  color: white !important;
}

::placeholder {
  color: white !important;
}

.test {
  color: #000000;
}

.tg-primary-text {
  color: #ed1c24 !important;
}

.videoTag {
  z-index: -9;
  width: 100%;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .videoTag {
    height: 519px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .videoTag {
    height: 503px;
    width: 100%;
  }
}

.main_banner {
  height: 632px;
  margin-top: -141px;
  background: rgba(0, 0, 0, 0.5);
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner {
    height: 419px;
  }
}
@media (max-width: 480px) {
  .main_banner {
    height: 364px;
  }
}
.main_banner h1 {
  font-size: 75px;
}
@media (max-width: 1000px) {
  .main_banner h1 {
    font-size: 55px;
  }
}
.main_banner .bannerimage {
  background: url("../img/guna_homes_main_banner1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: absolute;
  width: 100%;
  z-index: -99;
}
.main_banner .overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 647px;
  z-index: -9;
  position: absolute;
  right: 0;
  top: 0;
}
.main_banner .guna {
  background: url(../img/guna_transparent.png);
  width: 304px;
  height: 359px;
  bottom: 0;
  top: 270px;
  right: 9%;
  background-size: 300px;
  background-repeat: no-repeat;
  z-index: 999;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 283px;
    height: 299px;
    top: 173px;
    right: 0;
  }
}
@media (max-width: 480px) {
  .main_banner .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 204px;
    height: 235px;
    top: 157px;
    right: 0;
  }
}
.main_banner .banner_content {
  position: relative;
  top: 309px;
}
@media (max-width: 1000px) {
  .main_banner .banner_content {
    font-size: 55px;
  }
}
.main_banner .banner_content .bannerimage {
  background: url("../img/guna_homes_main_banner1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: absolute;
  width: 100%;
  z-index: -99;
}
.main_banner .banner_content .overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 647px;
  z-index: -9;
  position: absolute;
  right: 0;
  top: 0;
}
.main_banner .banner_content .guna {
  background: url(../img/guna_transparent.png);
  width: 304px;
  height: 359px;
  bottom: 0;
  top: 270px;
  right: 9%;
  background-size: 300px;
  background-repeat: no-repeat;
  z-index: 999;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner .banner_content .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 283px;
    height: 299px;
    top: 173px;
    right: 0;
  }
}
@media (max-width: 480px) {
  .main_banner .banner_content .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 204px;
    height: 235px;
    top: 157px;
    right: 0;
  }
}
.main_banner .banner_content .banner_content {
  position: relative;
  top: 309px;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner .banner_content .banner_content {
    top: 242px;
  }
}

.serchengine {
  z-index: 999 !important;
  position: relative;
}
.serchengine input,
.serchengine select {
  border: 1px solid #9e9e9e;
  height: 50px;
}
.serchengine .nav-pills .nav-link.active,
.serchengine .nav-pills .show > .nav-link {
  background: white;
  color: black;
  font-size: 20px;
}
@media (max-width: 480px) {
  .serchengine .nav-pills .nav-link.active,
.serchengine .nav-pills .show > .nav-link {
    font-size: 16px;
  }
}
.serchengine .nav-pills .nav-link {
  background: #8c8c8c;
  color: white;
  font-size: 20px;
  margin-right: 3px;
}
@media (max-width: 480px) {
  .serchengine .nav-pills .nav-link {
    font-size: 16px;
  }
}

.custom-padding {
  padding: 35px 0px !important;
}

.listing {
  background: url(../img/bg_1.jpg);
  background-size: 300px;
}
.listing .overlay {
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
}
.listing .filter-button {
  background: #1a1a1a !important;
  color: white;
  border-radius: 0;
}
.listing .filter-button:hover {
  background: #ed1c24 !important;
}
.listing .filter-button:focus {
  background: #ed1c24 !important;
}
.listing .sold_tag {
  background: #ffd700;
  padding: 10px 20px;
  color: black;
  font-weight: bold;
  position: absolute;
  text-transform: uppercase;
  z-index: 999;
}
.listing .column_wrap {
  width: 20%;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .listing .column_wrap {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .listing .column_wrap {
    width: 100%;
  }
}
.listing .column_wrap .item_wrapper {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.19);
  margin-bottom: 35px;
  margin-left: 10px;
  margin-right: 10px;
}
.listing .column_wrap .item_wrapper img {
  width: 100%;
  height: 156px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .listing .column_wrap .item_wrapper img {
    height: 280px;
  }
}
@media (max-width: 480px) {
  .listing .column_wrap .item_wrapper img {
    height: 280px;
  }
}
.listing .column_wrap .item_wrapper .owl-dots {
  margin-top: -24px !important;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .listing .column_wrap .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}
@media (max-width: 480px) {
  .listing .column_wrap .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}

.gunas_listing {
  background: url(../img/gnuas_listing_background.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.gunas_listing .item {
  border: 1px solid #d5d5d5;
  padding: 20px;
}
.gunas_listing .item .img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 330px;
  height: 292px;
}
@media (max-width: 480px) {
  .gunas_listing .item .img {
    width: 100%;
    height: 280px;
  }
}
.gunas_listing .item .details .title {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}
.gunas_listing .item .details h6 {
  text-align: center;
  padding-top: 10px;
}
.gunas_listing .item .details p {
  font-size: 22px;
  margin-top: 5px;
}
.gunas_listing .item .details .para {
  font-size: 17px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.gunas_listing .item .details .btn {
  background: #555555;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0;
}
.gunas_listing .item .details .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}
.gunas_listing .item .details .tag {
  position: absolute;
  left: 30px;
  top: 8px;
  padding: 5px 10px;
}
.gunas_listing .item .details .facilities {
  padding-top: 25px;
  padding-bottom: 15px;
  background: white;
  border-bottom: 1px solid #d7d7d7;
}
.gunas_listing .item .details .facilities .bed,
.gunas_listing .item .details .facilities .bath,
.gunas_listing .item .details .facilities .sqft {
  background: url(../img/guna_homes_listing_icons.png);
  background-size: 346%;
  width: 40px;
  height: 22px;
  opacity: 0.5;
}
.gunas_listing .item .details .facilities .bed {
  background-position: 0%;
}
.gunas_listing .item .details .facilities .bath {
  background-position: 56%;
}
.gunas_listing .item .details .facilities .sqft {
  background-position: 103%;
}
.gunas_listing .item .details .facilities .line {
  border-left: 1px solid #d7d7d7;
  margin-left: 10px;
  margin-right: 8px;
}
.gunas_listing .sold_listing .item .price_tag {
  top: 20px;
  z-index: 9999;
  left: 0;
  font-size: 21px;
  font-weight: bold;
}
.gunas_listing .sold_listing .item .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}

.featured_area .item {
  width: 100%;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  margin-bottom: 35px;
}
.featured_area .item .title {
  font-size: 25px;
  font-weight: bold;
  padding: 10px 20px;
  color: white;
  background: rgb(0, 0, 0) !important;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 212, 255, 0) 100%) !important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.mls_update {
  background: #000000 url(../img/bg1.png);
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .mls_update {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .mls_update {
    width: 100%;
  }
}
.mls_update .item_wrapper {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.19);
  margin-bottom: 35px;
  margin-left: 10px;
  margin-right: 10px;
}
.mls_update .item_wrapper .card-img-top {
  width: 200px;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 20% 10%;
     object-position: 20% 10%;
}
.mls_update .item_wrapper .owl-dots {
  margin-top: -24px !important;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .mls_update .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}
@media (max-width: 480px) {
  .mls_update .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}

.gunas_listing {
  background: url(../img/gnuas_listing_background.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.gunas_listing .item {
  border: 1px solid #d5d5d5;
  padding: 20px;
}
.gunas_listing .item .img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 330px;
  height: 292px;
}
@media (max-width: 480px) {
  .gunas_listing .item .img {
    width: 100%;
    height: 280px;
  }
}
.gunas_listing .item .details .title {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}
.gunas_listing .item .details h6 {
  text-align: center;
  padding-top: 10px;
}
.gunas_listing .item .details p {
  font-size: 22px;
  margin-top: 5px;
}
.gunas_listing .item .details .para {
  font-size: 17px;
  font-weight: 300;
}
.gunas_listing .item .details .btn {
  background: #555555;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0;
}
.gunas_listing .item .details .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}
.gunas_listing .item .details .tag {
  position: absolute;
  left: 30px;
  top: 8px;
  padding: 5px 10px;
}
.gunas_listing .item .details .facilities {
  padding-top: 25px;
  padding-bottom: 15px;
  background: white;
  border-bottom: 1px solid #d7d7d7;
}
.gunas_listing .item .details .facilities .bed,
.gunas_listing .item .details .facilities .bath,
.gunas_listing .item .details .facilities .sqft {
  background: url(../img/guna_homes_listing_icons.png);
  background-size: 346%;
  width: 40px;
  height: 22px;
  opacity: 0.5;
}
.gunas_listing .item .details .facilities .bed {
  background-position: 0%;
}
.gunas_listing .item .details .facilities .bath {
  background-position: 56%;
}
.gunas_listing .item .details .facilities .sqft {
  background-position: 103%;
}
.gunas_listing .item .details .facilities .line {
  border-left: 1px solid #d7d7d7;
  margin-left: 10px;
  margin-right: 8px;
}
.gunas_listing .sold_listing .item .price_tag {
  top: 20px;
  z-index: 9999;
  left: 0;
  font-size: 21px;
  font-weight: bold;
}
.gunas_listing .sold_listing .item .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}

.mls_update {
  background: #000000 url(../img/bg1.png);
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .mls_update {
    background: black;
  }
}
@media (max-width: 480px) {
  .mls_update {
    background: black;
  }
}
.mls_update input,
.mls_update select {
  border: 1px solid #8c8c8c;
}
.mls_update img {
  bottom: -64px;
  /* top: 0; */
  right: -40px;
}

.highlights .item .img {
  height: 400px;
}

.properpty_listing .prop_image {
  width: 100%;
  height: 130px;
  -o-object-fit: cover;
     object-fit: cover;
}

.related_properties .item .img {
  width: 100%;
  height: 130px;
  -o-object-fit: cover;
     object-fit: cover;
}

table thead {
  background-color: #e5e5e5;
}

::-moz-placeholder {
  color: #9e9e9e !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: #9e9e9e !important;
  opacity: 1; /* Firefox */
}

::placeholder {
  color: #9e9e9e !important;
  opacity: 1; /* Firefox */
}

ul .active {
  font-weight: 600 !important;
}

input#feedback-search {
  width: 100%;
  height: 40px;
  color: #424242;
  font-weight: 600;
  font-size: 14px;
  border: none !important;
}

input#feedback-search:focus {
  outline: none !important;
}

input#feedback-search::-moz-placeholder {
  font-size: 14px;
}

input#feedback-search:-ms-input-placeholder {
  font-size: 14px;
}

input#feedback-search::placeholder {
  font-size: 14px;
}

.send-email-container {
  margin: 0px 20px;
}

.send-email {
  margin: 0 auto;
  width: 70%;
}

.swal2-top-end {
  z-index: 1500 !important;
}
.swal2-top-end .swal2-popup {
  width: 27%;
}
.swal2-top-end .swal2-popup p {
  color: #bd0707;
  font-weight: 600;
}

.swal2-container {
  z-index: 10000 !important;
}

.blog-card img.blog-featured-image {
  height: 200px !important;
}