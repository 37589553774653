@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap);
.bg_primary {
  background: #1a1a1a !important;
}

.bg_secondary {
  background: #ed1c24 !important;
}

.bg_yellow {
  background: #ffd700;
}

.bg_blue {
  background: #270486;
}

.bg_gray {
  background: #555555;
}

.bg_ash {
  background: #f3f3f3;
}

.bg_ash2 {
  background: #f9f9f9;
}

.bg_black {
  background: black !important;
}

.bg_orange {
  background: #ec5717;
}

.bg_white_edgar {
  background: #ededed;
}

.bg_white_transparent_9 {
  background: rgba(255, 255, 255, 0.9);
}

.bg_black_transparent_7 {
  background: rgba(0, 0, 0, 0.7);
}

.fw_bold {
  font-weight: 700;
}

.fw_semi_bold {
  font-weight: 600;
}

.text_secondary {
  color: #ed1c24;
}

.text_orange {
  color: #ec5717;
}

.bg_none {
  background: none;
}

.border_none {
  border: none !important;
}

.border_radius_none {
  border-radius: 0 !important;
}

.bg_cover {
  background-size: cover !important;
}

.bg_norepeat {
  background-repeat: no-repeat !important;
}

.bg_center {
  background-position: center !important;
}

.border_shadow1 {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
}

.vm_fs_21 {
  font-size: 21px;
}

.vm_fs_18 {
  font-size: 18px !important;
}

.vm_fs_15 {
  font-size: 15px !important;
}

.vm_fs_13 {
  font-size: 13px !important;
}

.vm_lh_18 {
  line-height: 18px !important;
}

body {
  color: #000000;
  font-family: "Barlow";
}

p {
  line-height: 28px !important;
  color: #000000;
  font-size: 18px !important;
  font-family: "Barlow";
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-family: "Barlow";
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 55px;
  line-height: 45px;
}

h3 {
  font-size: 40px;
  line-height: 36px;
}

h4 {
  font-size: 32px;
  line-height: 36px;
}

h5 {
  font-size: 25px;
  line-height: 36px;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.container {
  max-width: 1500px !important;
}

::-webkit-input-placeholder {
  color: white !important;
}

::placeholder {
  color: white !important;
}

.test {
  color: #000000;
}

.tg-primary-text {
  color: #ed1c24 !important;
}

.videoTag {
  z-index: -9;
  width: 100%;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .videoTag {
    height: 519px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .videoTag {
    height: 503px;
    width: 100%;
  }
}

.main_banner {
  height: 632px;
  margin-top: -141px;
  background: rgba(0, 0, 0, 0.5);
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner {
    height: 419px;
  }
}
@media (max-width: 480px) {
  .main_banner {
    height: 364px;
  }
}
.main_banner h1 {
  font-size: 75px;
}
@media (max-width: 1000px) {
  .main_banner h1 {
    font-size: 55px;
  }
}
.main_banner .bannerimage {
  background: url(/static/media/guna_homes_main_banner1.9d59cfef.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: absolute;
  width: 100%;
  z-index: -99;
}
.main_banner .overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 647px;
  z-index: -9;
  position: absolute;
  right: 0;
  top: 0;
}
.main_banner .guna {
  background: url(/static/media/guna_transparent.6ca70f7c.png);
  width: 304px;
  height: 359px;
  bottom: 0;
  top: 270px;
  right: 9%;
  background-size: 300px;
  background-repeat: no-repeat;
  z-index: 999;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 283px;
    height: 299px;
    top: 173px;
    right: 0;
  }
}
@media (max-width: 480px) {
  .main_banner .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 204px;
    height: 235px;
    top: 157px;
    right: 0;
  }
}
.main_banner .banner_content {
  position: relative;
  top: 309px;
}
@media (max-width: 1000px) {
  .main_banner .banner_content {
    font-size: 55px;
  }
}
.main_banner .banner_content .bannerimage {
  background: url(/static/media/guna_homes_main_banner1.9d59cfef.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: absolute;
  width: 100%;
  z-index: -99;
}
.main_banner .banner_content .overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 647px;
  z-index: -9;
  position: absolute;
  right: 0;
  top: 0;
}
.main_banner .banner_content .guna {
  background: url(/static/media/guna_transparent.6ca70f7c.png);
  width: 304px;
  height: 359px;
  bottom: 0;
  top: 270px;
  right: 9%;
  background-size: 300px;
  background-repeat: no-repeat;
  z-index: 999;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner .banner_content .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 283px;
    height: 299px;
    top: 173px;
    right: 0;
  }
}
@media (max-width: 480px) {
  .main_banner .banner_content .guna {
    background-repeat: no-repeat;
    background-size: 95%;
    width: 204px;
    height: 235px;
    top: 157px;
    right: 0;
  }
}
.main_banner .banner_content .banner_content {
  position: relative;
  top: 309px;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .main_banner .banner_content .banner_content {
    top: 242px;
  }
}

.serchengine {
  z-index: 999 !important;
  position: relative;
}
.serchengine input,
.serchengine select {
  border: 1px solid #9e9e9e;
  height: 50px;
}
.serchengine .nav-pills .nav-link.active,
.serchengine .nav-pills .show > .nav-link {
  background: white;
  color: black;
  font-size: 20px;
}
@media (max-width: 480px) {
  .serchengine .nav-pills .nav-link.active,
.serchengine .nav-pills .show > .nav-link {
    font-size: 16px;
  }
}
.serchengine .nav-pills .nav-link {
  background: #8c8c8c;
  color: white;
  font-size: 20px;
  margin-right: 3px;
}
@media (max-width: 480px) {
  .serchengine .nav-pills .nav-link {
    font-size: 16px;
  }
}

.custom-padding {
  padding: 35px 0px !important;
}

.listing {
  background: url(/static/media/bg_1.a94574fa.jpg);
  background-size: 300px;
}
.listing .overlay {
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
}
.listing .filter-button {
  background: #1a1a1a !important;
  color: white;
  border-radius: 0;
}
.listing .filter-button:hover {
  background: #ed1c24 !important;
}
.listing .filter-button:focus {
  background: #ed1c24 !important;
}
.listing .sold_tag {
  background: #ffd700;
  padding: 10px 20px;
  color: black;
  font-weight: bold;
  position: absolute;
  text-transform: uppercase;
  z-index: 999;
}
.listing .column_wrap {
  width: 20%;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .listing .column_wrap {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .listing .column_wrap {
    width: 100%;
  }
}
.listing .column_wrap .item_wrapper {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.19);
  margin-bottom: 35px;
  margin-left: 10px;
  margin-right: 10px;
}
.listing .column_wrap .item_wrapper img {
  width: 100%;
  height: 156px;
  object-fit: cover;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .listing .column_wrap .item_wrapper img {
    height: 280px;
  }
}
@media (max-width: 480px) {
  .listing .column_wrap .item_wrapper img {
    height: 280px;
  }
}
.listing .column_wrap .item_wrapper .owl-dots {
  margin-top: -24px !important;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .listing .column_wrap .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}
@media (max-width: 480px) {
  .listing .column_wrap .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}

.gunas_listing {
  background: url(/static/media/gnuas_listing_background.8c6317e8.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.gunas_listing .item {
  border: 1px solid #d5d5d5;
  padding: 20px;
}
.gunas_listing .item .img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 330px;
  height: 292px;
}
@media (max-width: 480px) {
  .gunas_listing .item .img {
    width: 100%;
    height: 280px;
  }
}
.gunas_listing .item .details .title {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}
.gunas_listing .item .details h6 {
  text-align: center;
  padding-top: 10px;
}
.gunas_listing .item .details p {
  font-size: 22px;
  margin-top: 5px;
}
.gunas_listing .item .details .para {
  font-size: 17px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.gunas_listing .item .details .btn {
  background: #555555;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0;
}
.gunas_listing .item .details .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}
.gunas_listing .item .details .tag {
  position: absolute;
  left: 30px;
  top: 8px;
  padding: 5px 10px;
}
.gunas_listing .item .details .facilities {
  padding-top: 25px;
  padding-bottom: 15px;
  background: white;
  border-bottom: 1px solid #d7d7d7;
}
.gunas_listing .item .details .facilities .bed,
.gunas_listing .item .details .facilities .bath,
.gunas_listing .item .details .facilities .sqft {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAAaCAMAAABLhmixAAAAolBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACgESU6AAAANXRSTlMAnXymmLcNoFjIvht1u6KzSs9pjnNNBN3n6trSq4dy7+GwXBLkw0Q8MijWkmEtJPZ/b1QXOAatO9AAAAHoSURBVEjH7dbZkppAGIbhD1kUHUBpZCcsivuuff+3loiEgUw3gUlOpmqeo/9Xq96ikGrw7YvRd4INNsdbxqfmTqvlno4/MCboZmWKeX50DmCjKyUPpQqJ15Ok+jJI3z4QfRudCPRyPiK98MIbaKpWMeKt7aCk7MDgd73kUDQ2kC2wxUv/vK3t3tKnVVhlNBazbmGFIKDghxfW7m7Xd6Le/0N4ODId4NgSxlxt7lcD7+E7bOWp2J5sKEGH8NqbKM/L6RP+UQ/bg93sSVXsvBiCCF2uWMFx+fiHcHrzjpMn7WKpxbD1sm7P01XC58MQsiEK0zerfB71wxB9hNknwib1qVBwXNd7TdShVsu91cWSXk7UX/UOD+JoH4mFPSHkNf36yI3AQ8ngDyOL9g4HNzBNE/BoOiF7qY4Mx73DGuf23FTw2PRtvprWmVTpHTbmYFqNwSVn1y3qDjn6hhe+PmIS3AV4LkQQUKcRsClmoDT2KC93YyYNmSQ1Bc9JftDG39jfgM1zk8Yh4boJRSHQsmzAMMrSGbhU6VZP6QHajkW5kr4fi49kSQUG6rprcJ2CaK/JL1qY7k7c8NoeG3pFijeLMozQDcEgejLahNT8jbb8Mkn85qtP4pzL8RBOwbAOTbSJrGq8Enz7m5+UyDDlzE2DmAAAAABJRU5ErkJggg==);
  background-size: 346%;
  width: 40px;
  height: 22px;
  opacity: 0.5;
}
.gunas_listing .item .details .facilities .bed {
  background-position: 0%;
}
.gunas_listing .item .details .facilities .bath {
  background-position: 56%;
}
.gunas_listing .item .details .facilities .sqft {
  background-position: 103%;
}
.gunas_listing .item .details .facilities .line {
  border-left: 1px solid #d7d7d7;
  margin-left: 10px;
  margin-right: 8px;
}
.gunas_listing .sold_listing .item .price_tag {
  top: 20px;
  z-index: 9999;
  left: 0;
  font-size: 21px;
  font-weight: bold;
}
.gunas_listing .sold_listing .item .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}

.featured_area .item {
  width: 100%;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  margin-bottom: 35px;
}
.featured_area .item .title {
  font-size: 25px;
  font-weight: bold;
  padding: 10px 20px;
  color: white;
  background: rgb(0, 0, 0) !important;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 212, 255, 0) 100%) !important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.mls_update {
  background: #000000 url(/static/media/bg1.6190fa78.png);
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .mls_update {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .mls_update {
    width: 100%;
  }
}
.mls_update .item_wrapper {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.19);
  margin-bottom: 35px;
  margin-left: 10px;
  margin-right: 10px;
}
.mls_update .item_wrapper .card-img-top {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: 20% 10%;
}
.mls_update .item_wrapper .owl-dots {
  margin-top: -24px !important;
  position: relative;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .mls_update .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}
@media (max-width: 480px) {
  .mls_update .item_wrapper .owl-dots {
    margin-top: -35px !important;
  }
}

.gunas_listing {
  background: url(/static/media/gnuas_listing_background.8c6317e8.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.gunas_listing .item {
  border: 1px solid #d5d5d5;
  padding: 20px;
}
.gunas_listing .item .img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 330px;
  height: 292px;
}
@media (max-width: 480px) {
  .gunas_listing .item .img {
    width: 100%;
    height: 280px;
  }
}
.gunas_listing .item .details .title {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}
.gunas_listing .item .details h6 {
  text-align: center;
  padding-top: 10px;
}
.gunas_listing .item .details p {
  font-size: 22px;
  margin-top: 5px;
}
.gunas_listing .item .details .para {
  font-size: 17px;
  font-weight: 300;
}
.gunas_listing .item .details .btn {
  background: #555555;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0;
}
.gunas_listing .item .details .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}
.gunas_listing .item .details .tag {
  position: absolute;
  left: 30px;
  top: 8px;
  padding: 5px 10px;
}
.gunas_listing .item .details .facilities {
  padding-top: 25px;
  padding-bottom: 15px;
  background: white;
  border-bottom: 1px solid #d7d7d7;
}
.gunas_listing .item .details .facilities .bed,
.gunas_listing .item .details .facilities .bath,
.gunas_listing .item .details .facilities .sqft {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAAaCAMAAABLhmixAAAAolBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACgESU6AAAANXRSTlMAnXymmLcNoFjIvht1u6KzSs9pjnNNBN3n6trSq4dy7+GwXBLkw0Q8MijWkmEtJPZ/b1QXOAatO9AAAAHoSURBVEjH7dbZkppAGIbhD1kUHUBpZCcsivuuff+3loiEgUw3gUlOpmqeo/9Xq96ikGrw7YvRd4INNsdbxqfmTqvlno4/MCboZmWKeX50DmCjKyUPpQqJ15Ok+jJI3z4QfRudCPRyPiK98MIbaKpWMeKt7aCk7MDgd73kUDQ2kC2wxUv/vK3t3tKnVVhlNBazbmGFIKDghxfW7m7Xd6Le/0N4ODId4NgSxlxt7lcD7+E7bOWp2J5sKEGH8NqbKM/L6RP+UQ/bg93sSVXsvBiCCF2uWMFx+fiHcHrzjpMn7WKpxbD1sm7P01XC58MQsiEK0zerfB71wxB9hNknwib1qVBwXNd7TdShVsu91cWSXk7UX/UOD+JoH4mFPSHkNf36yI3AQ8ngDyOL9g4HNzBNE/BoOiF7qY4Mx73DGuf23FTw2PRtvprWmVTpHTbmYFqNwSVn1y3qDjn6hhe+PmIS3AV4LkQQUKcRsClmoDT2KC93YyYNmSQ1Bc9JftDG39jfgM1zk8Yh4boJRSHQsmzAMMrSGbhU6VZP6QHajkW5kr4fi49kSQUG6rprcJ2CaK/JL1qY7k7c8NoeG3pFijeLMozQDcEgejLahNT8jbb8Mkn85qtP4pzL8RBOwbAOTbSJrGq8Enz7m5+UyDDlzE2DmAAAAABJRU5ErkJggg==);
  background-size: 346%;
  width: 40px;
  height: 22px;
  opacity: 0.5;
}
.gunas_listing .item .details .facilities .bed {
  background-position: 0%;
}
.gunas_listing .item .details .facilities .bath {
  background-position: 56%;
}
.gunas_listing .item .details .facilities .sqft {
  background-position: 103%;
}
.gunas_listing .item .details .facilities .line {
  border-left: 1px solid #d7d7d7;
  margin-left: 10px;
  margin-right: 8px;
}
.gunas_listing .sold_listing .item .price_tag {
  top: 20px;
  z-index: 9999;
  left: 0;
  font-size: 21px;
  font-weight: bold;
}
.gunas_listing .sold_listing .item .hvr-shutter-out-horizontal::before {
  background: #ed1c24;
}

.mls_update {
  background: #000000 url(/static/media/bg1.6190fa78.png);
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 481px) and (max-width: 1000px) {
  .mls_update {
    background: black;
  }
}
@media (max-width: 480px) {
  .mls_update {
    background: black;
  }
}
.mls_update input,
.mls_update select {
  border: 1px solid #8c8c8c;
}
.mls_update img {
  bottom: -64px;
  /* top: 0; */
  right: -40px;
}

.highlights .item .img {
  height: 400px;
}

.properpty_listing .prop_image {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

.related_properties .item .img {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

table thead {
  background-color: #e5e5e5;
}

::-webkit-input-placeholder {
  color: #9e9e9e !important;
  opacity: 1; /* Firefox */
}

::placeholder {
  color: #9e9e9e !important;
  opacity: 1; /* Firefox */
}

ul .active {
  font-weight: 600 !important;
}

input#feedback-search {
  width: 100%;
  height: 40px;
  color: #424242;
  font-weight: 600;
  font-size: 14px;
  border: none !important;
}

input#feedback-search:focus {
  outline: none !important;
}

input#feedback-search::-webkit-input-placeholder {
  font-size: 14px;
}

input#feedback-search::placeholder {
  font-size: 14px;
}

.send-email-container {
  margin: 0px 20px;
}

.send-email {
  margin: 0 auto;
  width: 70%;
}

.swal2-top-end {
  z-index: 1500 !important;
}
.swal2-top-end .swal2-popup {
  width: 27%;
}
.swal2-top-end .swal2-popup p {
  color: #bd0707;
  font-weight: 600;
}

.swal2-container {
  z-index: 10000 !important;
}

.blog-card img.blog-featured-image {
  height: 200px !important;
}
.post .article-v2 {
  padding: 0 15px;
}

.post .article-v2 .entry-title {
  font-size: 16px;
  color: #666666;
  font-weight: 600;
}

.post .article-v2 .article-header {
  margin-bottom: 15px;
}

.post .article-v2 .article-content-main {
  box-shadow: 1px 0px 20px 1px rgba(0, 0, 0, 0.15);
  padding: 30px;
  position: relative;
  background: #fff;
  margin-top: -75px;
}

@media only screen and (max-width: 1024px) {
  .post .article-v2 .article-content-main {
    padding: 15px;
    margin-bottom: 30px;
  }
}

.post .article-v2 .article-content p:last-of-type {
  margin-bottom: 0;
}

.post .article-v2 .article-thumb {
  position: relative;
  left: 25px;
  margin-bottom: 0;
}

.post .article-v2 .article-footer {
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  margin-top: 20px;
}

.post .article-v2 .article-footer .more-link {
  color: #ffa800;
}

.post .article-v2 .article-footer .footer-meta a {
  color: #aaa;
  font-size: 15px;
  margin-right: 5px;
}

.post .article-v2 .article-footer .footer-meta a i {
  font-size: 16px;
  margin-left: 2px;
}

.bg_primary {
  background: #1a1a1a !important;
}

.bg_secondary {
  background: #ed1c24 !important;
}

.bg_yellow {
  background: #ffd700;
}

.bg_blue {
  background: #270486;
}

.bg_gray {
  background: #555555;
}

.bg_ash {
  background: #f3f3f3;
}

.bg_ash2 {
  background: #f9f9f9;
}

.bg_black {
  background: black !important;
}

.bg_orange {
  background: #ec5717;
}

.bg_white_edgar {
  background: #ededed;
}

.bg_white_transparent_9 {
  background: rgba(255, 255, 255, 0.9);
}

.bg_black_transparent_7 {
  background: rgba(0, 0, 0, 0.7);
}

.fw_bold {
  font-weight: 700;
}

.fw_semi_bold {
  font-weight: 600;
}

.text_secondary {
  color: #ed1c24;
}

.text_orange {
  color: #ec5717;
}

.bg_none {
  background: none;
}

.border_none {
  border: none !important;
}

.border_radius_none {
  border-radius: 0 !important;
}

.bg_cover {
  background-size: cover !important;
}

.bg_norepeat {
  background-repeat: no-repeat !important;
}

.bg_center {
  background-position: center !important;
}

.border_shadow1 {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
}

.vm_fs_21 {
  font-size: 21px;
}

.vm_fs_18 {
  font-size: 18px !important;
}

.vm_fs_15 {
  font-size: 15px !important;
}

.vm_fs_13 {
  font-size: 13px !important;
}

.vm_lh_18 {
  line-height: 18px !important;
}

body {
  color: #000000;
  font-family: "Barlow";
}

p {
  line-height: 28px !important;
  color: #000000;
  font-size: 18px !important;
  font-family: "Barlow";
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-family: "Barlow";
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 55px;
  line-height: 45px;
}

h3 {
  font-size: 40px;
  line-height: 36px;
}

h4 {
  font-size: 32px;
  line-height: 36px;
}

h5 {
  font-size: 25px;
  line-height: 36px;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.container {
  max-width: 1500px !important;
}

::-webkit-input-placeholder {
  color: white !important;
}

::placeholder {
  color: white !important;
}

header .first_layer {
  z-index: 99;
  position: relative;
}
header .second_layer {
  background: rgba(237, 28, 36, 0.7);
}
@media (min-width: 481px) and (max-width: 1000px) {
  header {
    background: black;
  }
}
@media (max-width: 480px) {
  header {
    background: black;
  }
}
header .overlay_black {
  height: 504px;
}
header .logo_wrapper {
  -webkit-filter: drop-shadow(11px 8px 9px rgba(50, 50, 0, 0.1));
          filter: drop-shadow(11px 8px 9px rgba(50, 50, 0, 0.1));
  height: 137px;
  width: 30%;
  position: absolute;
  left: 0;
  z-index: 99;
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .logo_wrapper {
    position: relative;
    width: 100%;
    height: 97px;
  }
}
@media (max-width: 480px) {
  header .logo_wrapper {
    position: relative;
    width: 100%;
    height: 97px;
  }
}
header .logo_wrapper .logo {
  height: 137px;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
  padding-right: 80px;
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .logo_wrapper .logo {
    height: 99px;
    -webkit-clip-path: none;
            clip-path: none;
    padding-right: 0;
    padding-left: 20px;
  }
}
@media (max-width: 480px) {
  header .logo_wrapper .logo {
    height: 99px;
    -webkit-clip-path: none;
            clip-path: none;
    padding-right: 0;
    padding-left: 20px;
  }
}
header .menu_wrapper {
  width: 71%;
  z-index: 800;
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper {
    padding: 0;
    width: 100%;
    position: relative;
    background: #ed1c24 !important;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper {
    padding: 0;
    width: 100%;
    position: relative;
    background: #ed1c24 !important;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .top_menu {
    background: #555555;
    display: block !important;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .top_menu {
    background: #555555;
    display: block !important;
  }
}
header .menu_wrapper .sign_menu {
  z-index: 999999;
  position: relative;
}
@media (max-width: 1000px) {
  header .menu_wrapper .sign_menu {
    font-size: 20px;
  }
}
header .menu_wrapper .sign_menu .showing {
  background: #f3f3f3;
  color: black;
  border-radius: 4px;
}
@media (max-width: 1000px) {
  header .menu_wrapper .sign_menu .showing {
    background: #ed1c24;
    color: white;
    border-radius: 0;
    padding: 5px;
    text-align: center;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .sign_menu .item {
    width: 100%;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu .item {
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .sign_menu .item p {
    background: #ed1c24;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  header .menu_wrapper .sign_menu .item p:first-child {
    border-right: 1px solid white;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu .item p {
    background: #ed1c24;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu .item:first-child p {
    border-right: 1px solid white;
  }
}
@media (min-width: 481px) and (max-width: 1000px) {
  header .menu_wrapper .sign_menu {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
@media (max-width: 480px) {
  header .menu_wrapper .sign_menu {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
header .hvr-sweep-to-right:before {
  background: #ed1c24;
}

.scroll {
  color: #fff;
  margin-top: 100px;
}

#toTopBtn {
  position: fixed;
  bottom: 26px;
  right: 39px;
  z-index: 98;
  padding: 21px;
  background-color: #ed1c24;
}

.js .cd-top--fade-out {
  opacity: 0.5;
}

.js .cd-top--is-visible {
  visibility: visible;
  opacity: 1;
}

.js .cd-top {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s, background-color 0.3s;
}

.cd-top {
  position: fixed;
  bottom: 20px;
  bottom: var(--cd-back-to-top-margin);
  right: 20px;
  right: var(--cd-back-to-top-margin);
  display: inline-block;
  height: 40px;
  height: var(--cd-back-to-top-size);
  width: 40px;
  width: var(--cd-back-to-top-size);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05) !important;
  background: url(https://res.cloudinary.com/dxfq3iotg/image/upload/v1571057658/cd-top-arrow.svg) no-repeat center 50%;
  background-color: hsla(5deg, 76%, 62%, 0.8);
  background-color: hsla(var(--cd-color-3-h), var(--cd-color-3-s), var(--cd-color-3-l), 0.8);
}

.owl-carousel .owl-nav {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  transition: all 0.5 ease;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  background: rgba(237, 28, 36, 0.8) !important;
  height: 50px;
  width: 50px;
  margin-top: 0 !important;
  border-radius: 100% !important;
  color: white;
}
.owl-carousel .owl-nav .owl-prev span,
.owl-carousel .owl-nav .owl-next span {
  position: absolute;
  margin-top: -32px;
  color: white;
}
.owl-carousel .owl-nav .owl-prev {
  left: 21px;
}
.owl-carousel .owl-nav .owl-prev span {
  margin-left: -9px;
}
.owl-carousel .owl-nav .owl-next {
  right: 0px;
}
.owl-carousel .owl-nav .owl-next span {
  margin-left: -8px !important;
}
.owl-carousel:hover .owl-nav {
  opacity: 9;
}

/* vars */
/* hide show mixin */
/* global  styles */
.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu,
.menu a {
  color: #e2e2e2;
  text-decoration: none;
}

.menu a {
  display: block;
  white-space: nowrap;
}
@media (max-width: 1000px) {
  .menu a {
    text-align: center;
    line-height: 40px;
  }
}

.menu-dropdown,
.menu input[type=checkbox] {
  display: none;
}

.menu label:hover {
  cursor: pointer;
}

.mob_menu {
  display: none;
}

/* narrow styles */
@media screen and (max-width: 1024px) {
  .mob_menu {
    display: block;
  }
  .menu > ul,
.menu-righticon {
    display: none;
  }
  input[type=checkbox]:checked + ul {
    display: block;
    -webkit-animation: grow 0.5s ease-in-out;
            animation: grow 0.5s ease-in-out;
  }
}
@media screen and (max-width: 1024px) and (max-width: 1000px) {
  input[type=checkbox]:checked + ul {
    position: fixed;
    background: url(/static/media/guna_homes_main_banner1.9d59cfef.png);
    background-size: cover;
    width: 100%;
    top: 0;
    left: 0px;
    height: 100%;
    z-index: 999;
    padding: 10px 0px;
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  input[type=checkbox]:checked + ul .mob_menu {
    position: absolute;
    top: 20px;
    right: 0;
    background: black;
    padding: 5px 9px;
  }
}
@media screen and (max-width: 1024px) {
  input[type=checkbox]:checked + ul li {
    border-bottom: 1px solid #4a4a4a;
    width: 50%;
    padding-top: 3px;
    scroll-padding-bottom: 3px;
    background: rgba(0, 0, 0, 0.7);
  }
}
/* large styles */
@media screen and (min-width: 1025px) {
  .menu > label,
input[type=checkbox] {
    display: none;
    z-index: 9999999;
    position: relative;
  }
  .menu a {
    padding: 0 11px;
  }
  .menu > ul > li {
    display: inline-block;
  }
  .menu-hasdropdown {
    position: relative;
  }
  .menu-hasdropdown:hover > ul {
    display: block;
    -webkit-animation: grow 0.5s ease-in-out;
            animation: grow 0.5s ease-in-out;
  }
  .menu-hasdropdown > ul {
    position: absolute;
    top: 100%;
    left: 0;
    background: #000000;
    z-index: 99999;
    padding-top: 10px;
  }
  .menu-hasflyout > ul {
    left: 100%;
    top: 0;
  }
  .menu-hasflyout .menu-downicon {
    display: none;
  }
}
/* look and feel only, not needed for core menu*/
@-webkit-keyframes grow {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: block;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes grow {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: block;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.menu-dropdown a {
  padding: 5px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* narrow  */
@media screen and (max-width: 1024px) {
  .menu > label {
    background: black;
    display: block;
    padding: 0 11px;
    text-align: right;
  }
  .menu a {
    padding: 0 11px;
  }
  .menu > ul i {
    float: right;
    padding: 5px 10px;
    background: black;
  }
  .menu-dropdown a {
    background: black;
  }
  .menu-hasflyout > ul a {
    background: black;
  }
}
@media screen and (min-width: 1025px) {
  .menu {
    max-width: 1024px;
    margin: 0 auto;
  }
}
footer {
  background: url(/static/media/guna_homes_footer_background.9119ebaa.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 80px;
}
footer .top {
  border-bottom: 1px solid #484848;
}
footer .top .line {
  border-left: 1px solid #484848;
  margin-left: 25px;
}

.footer-links div :hover {
  cursor: pointer;
}
img.post-card-image {
  height: 180px;
  width: 100% !important;
}

.link-text {
  color: #070985;
}

p.excerpt {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post .article-v2 {
  padding: 0 15px;
}

.post .article-v2 .entry-title {
  font-size: 16px;
  color: #666666;
  font-weight: 600;
}

.post .article-v2 .article-header {
  margin-bottom: 15px;
}

.post .article-v2 .article-content-main {
  box-shadow: 1px 0px 20px 1px rgba(0, 0, 0, 0.15);
  padding: 30px;
  position: relative;
  background: #fff;
  margin-top: -75px;
}

@media only screen and (max-width: 1024px) {
  .post .article-v2 .article-content-main {
    padding: 15px;
    margin-bottom: 30px;
  }
}

.post .article-v2 .article-content p:last-of-type {
  margin-bottom: 0;
}

.post .article-v2 .article-thumb {
  position: relative;
  left: 25px;
  margin-bottom: 0;
}

.post .article-v2 .article-footer {
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  margin-top: 20px;
}

.post .article-v2 .article-footer .more-link {
  color: #ffa800;
}

.post .article-v2 .article-footer .footer-meta a {
  color: #aaa;
  font-size: 15px;
  margin-right: 5px;
}

.post .article-v2 .article-footer .footer-meta a i {
  font-size: 16px;
  margin-left: 2px;
}

/* CSS Loader  */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  /*background: #28a745;*/
  background: #dedede;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}


body {
  margin-top: 20px;
}
.blog-listing {
  padding-top: 30px;
  padding-bottom: 30px;
}
.gray-bg {
  background-color: #f5f5f5;
}
/* Blog 
---------------------*/
.blog-grid {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
}
.blog-grid .blog-img {
  position: relative;
}
.blog-grid .blog-img .date {
  position: absolute;
  background: #fc5356;
  color: #ffffff;
  padding: 8px 15px;
  left: 10px;
  top: 10px;
  border-radius: 4px;
}
.blog-grid .blog-img .date span {
  font-size: 22px;
  display: block;
  line-height: 22px;
  font-weight: 700;
}
.blog-grid .blog-img .date label {
  font-size: 14px;
  margin: 0;
}
.blog-grid .blog-info {
  padding: 20px;
}
.blog-grid .blog-info h5 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 10px;
}
.blog-grid .blog-info h5 a {
  color: #ed1c24;
}
.blog-grid .blog-info p {
  margin: 0;
}
.blog-grid .blog-info .btn-bar {
  margin-top: 20px;
}

/* Blog Sidebar
-------------------*/
.blog-aside .widget {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.blog-aside .widget-body {
  padding: 15px;
}
.blog-aside .widget-title {
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.blog-aside .widget-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: #fc5356;
  margin: 0;
}
.blog-aside .widget-author .media {
  margin-bottom: 15px;
}
.blog-aside .widget-author p {
  font-size: 16px;
  margin: 0;
}
.blog-aside .widget-author .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.blog-aside .widget-author h6 {
  font-weight: 600;
  color: #ed1c24;
  font-size: 22px;
  margin: 0;
  padding-left: 20px;
}
.blog-aside .post-aside {
  margin-bottom: 15px;
}
.blog-aside .post-aside .post-aside-title h5 {
  margin: 0;
}
.blog-aside .post-aside .post-aside-title a {
  font-size: 18px;
  color: #ed1c24;
  font-weight: 600;
}
.blog-aside .post-aside .post-aside-meta {
  padding-bottom: 10px;
}
.blog-aside .post-aside .post-aside-meta a {
  color: #6f8ba4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}
.blog-aside .latest-post-aside + .latest-post-aside {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
}
.blog-aside .latest-post-aside .lpa-right {
  width: 90px;
}
.blog-aside .latest-post-aside .lpa-right img {
  border-radius: 3px;
}
.blog-aside .latest-post-aside .lpa-left {
  padding-right: 15px;
}
.blog-aside .latest-post-aside .lpa-title h5 {
  margin: 0;
  font-size: 15px;
}
.blog-aside .latest-post-aside .lpa-title a {
  color: #ed1c24;
  font-weight: 600;
}
.blog-aside .latest-post-aside .lpa-meta a {
  color: #6f8ba4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}

.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #ffffff;
  background: #ed1c24;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
}
.tag-cloud a:hover {
  background: #fc5356;
}

.blog-single {
  padding-top: 30px;
  padding-bottom: 30px;
}

.article {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 15px;
  margin: 15px 0 30px;
}
.article .article-title {
  padding: 15px 0 20px;
}
.article .article-title h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.article .article-title h6 a {
  text-transform: uppercase;
  color: #fc5356;
  border-bottom: 1px solid #fc5356;
}
.article .article-title h2 {
  color: #ed1c24;
  font-weight: 600;
}
.article .article-title .media {
  padding-top: 15px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px;
}
.article .article-title .media .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.article .article-title .media .media-body {
  padding-left: 8px;
}
.article .article-title .media .media-body label {
  font-weight: 600;
  color: #fc5356;
  margin: 0;
}
.article .article-title .media .media-body span {
  display: block;
  font-size: 12px;
}
.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
  color: #ed1c24;
  font-weight: 600;
  margin-bottom: 15px;
}
.article .article-content blockquote {
  max-width: 600px;
  padding: 15px 0 30px 0;
  margin: 0;
}
.article .article-content blockquote p {
  font-size: 20px;
  font-weight: 500;
  color: #fc5356;
  margin: 0;
}
.article .article-content blockquote .blockquote-footer {
  color: #ed1c24;
  font-size: 16px;
}
.article .article-content blockquote .blockquote-footer cite {
  font-weight: 600;
}
.article .tag-cloud {
  padding-top: 10px;
}

.article-comment {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 20px;
}
.article-comment h4 {
  color: #ed1c24;
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 22px;
}
img {
  max-width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}

/* Contact Us
---------------------*/
.contact-name {
  margin-bottom: 30px;
}
.contact-name h5 {
  font-size: 22px;
  color: #ed1c24;
  margin-bottom: 5px;
  font-weight: 600;
}
.contact-name p {
  font-size: 18px;
  margin: 0;
}

.social-share a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
}
.social-share .dribbble {
  box-shadow: 0 8px 30px -4px rgba(234, 76, 137, 0.5);
  background-color: #ea4c89;
}
.social-share .behance {
  box-shadow: 0 8px 30px -4px rgba(0, 103, 255, 0.5);
  background-color: #0067ff;
}
.social-share .linkedin {
  box-shadow: 0 8px 30px -4px rgba(1, 119, 172, 0.5);
  background-color: #0177ac;
}

.contact-form .form-control {
  border: none;
  border-bottom: 1px solid #ed1c24;
  background: transparent;
  border-radius: 0;
  padding-left: 0;
  box-shadow: none !important;
}
.contact-form .form-control:focus {
  border-bottom: 1px solid #fc5356;
}
.contact-form .form-control.invalid {
  border-bottom: 1px solid #ff0000;
}
.contact-form .send {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .contact-form .send {
    margin-bottom: 20px;
  }
}

.section-title h2 {
  font-weight: 700;
  color: #ed1c24;
  font-size: 45px;
  margin: 0 0 15px;
  border-left: 5px solid #fc5356;
  padding-left: 15px;
}
.section-title {
  padding-bottom: 45px;
}
.contact-form .send {
  margin-top: 20px;
}
.px-btn {
  padding: 0 50px 0 20px;
  line-height: 60px;
  position: relative;
  display: inline-block;
  color: #ed1c24;
  background: none;
  border: none;
}
.px-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 30px;
  background: transparent;
  border: 1px solid rgba(252, 83, 86, 0.6);
  border-right: 1px solid transparent;
  transition: ease all 0.35s;
  width: 60px;
  height: 60px;
}
.px-btn .arrow {
  width: 13px;
  height: 2px;
  background: currentColor;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 25px;
}
.px-btn .arrow:after {
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-top: 2px solid currentColor;
  content: "";
  position: absolute;
  top: -3px;
  right: 0;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.news-item img {
  width: 100%;
  max-height: 250px;
  height: 250px;
  overflow: hidden;
  object-fit: cover;
}

.news-item .descr {
  padding: 10px;
  color: #fff;
  text-align: center;
}

.decor-success {
  background-color: #46be8a !important;
}

/* .decor-info {
  background-color: #39bee8 !important;
} */

.decor-primary {
  background-color: #7266ba !important;
}

.decor-warning {
  background-color: #f2a654 !important;
}

.decor-danger {
  background-color: #ff69b4 !important;
}

.decor-primary-hue {
  background-color: #585b9c !important;
}

.news-item .descr h5 a {
  color: #fff;
  text-decoration: none;
}

/* .m-b-md,
.news-item {
  margin-bottom: 30px !important;
} */

.news-item .descr a.readmore:hover {
  text-decoration: none;
}
.news-item .descr a.readmore {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 24px;
  padding: 8px 22px;
}

